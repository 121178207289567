import React from "react";

function InputBox({
  label,
  placeholder,
  onChange,
  type,
  name,
  required,
  value,
  ...otherprops
}) {
  return (
    <div className="flex flex-col">
      <label className="text-sm text-gray-500 font-bold" htmlFor="">
        {label}
      </label>
      <input
        className="border border-gray-300 rounded-md px-2 py-1 disabled:bg-gray-300 disabled:cursor-not-allowed"
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        value={value}
        required={required}
        {...otherprops}
      />
    </div>
  );
}

export default InputBox;
