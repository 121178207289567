import React from "react";
import InputBox from "../../components/InputBox";
import { useState } from "react";
import { useSelector } from "react-redux";
import { createNewDoc } from "../../firebase/firebase.config";

function AddTaskForm({ project, setAddTaskMode }) {
  const [formData, setFormData] = useState({});
  const currentUser = useSelector((state) => state.userObject);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const docObject = {
      ...formData,
      taskStatus: "pending",
      projectId: project.id,
      assignedTo: [currentUser.email],
      createdBy: currentUser.email,
      createdAt: new Date().toISOString(),
      user: currentUser.email,
    };

    await createNewDoc("tasks", docObject);
    setFormData({});
    setIsProcessing(false);
    setAddTaskMode(false);
  };

  return (
    <div className="p-2 min-h-[500px] w-[350px]">
      <div>Add Task</div>
      <form action="" onSubmit={handleSubmit}>
        <div className="space-y-4">
          <InputBox
            label="Task name"
            placeholder="Enter task name"
            type="text"
            required
            name="taskName"
            onChange={handleChange}
            value={formData.taskName || ""}
          />
          <InputBox
            label="Task description"
            placeholder="Enter task description"
            type="text"
            name="taskDescription"
            onChange={handleChange}
            value={formData.taskDescription || ""}
          />
          <InputBox
            label="Task deadline"
            placeholder="Enter task deadline"
            type="date"
            required
            name="dueDate"
            onChange={handleChange}
            value={formData.dueDate || ""}
          />
        </div>

        <div className="mt-8">
          <button className="bg-blue-400 text-white p-2 rounded-lg w-full">
            {isProcessing ? "Adding task..." : "Add task"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddTaskForm;
