import React from "react";
import { NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../store/userSlice";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

function SidebarMenu({ links }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const menuStyle =
    "hover:bg-blue-200 mt-4 py-4 px-2 text-sm uppercase font-bold text-gray-700";

  return (
    <>
      <div className="">
        {links.map((item) => (
          <div key={item.label} className="w-full">
            <NavLink
              className="
            hover:bg-blue-200 py-4 px-2 text-sm uppercase font-bold text-gray-700 w-full block
            "
              to={item.link}
            >
              {item.label}
            </NavLink>
          </div>
        ))}
      </div>
    </>
  );
}

export default SidebarMenu;
