import React, { useState } from "react";
import InputBox from "../../components/InputBox";
import { useSelector } from "react-redux";
import { createNewDoc } from "../../firebase/firebase.config";

function AddNewProjectForm({ category }) {
  const currentUser = useSelector((state) => state.userObject);
  const [inProcess, setInProcess] = useState(false);
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInProcess(true);

    const docObject = {
      ...formData,
      category: category.id,
      createdAt: new Date().toISOString(),
      user: currentUser.email,
    };

    await createNewDoc("projects", docObject);
    setFormData({});
    setInProcess(false);
  };

  return (
    <div className="p-2 min-h-[500px] min-w-[450px]">
      <div>
        <InputBox
          label="Project name"
          placeholder="Enter project name"
          type="text"
          required
          name="projectName"
          onChange={handleChange}
          value={formData.projectName || ""}
        />
      </div>

      <div className="mt-8">
        <button
          className="bg-blue-400 text-white p-2 rounded-lg w-full"
          onClick={handleSubmit}
        >
          {inProcess ? "Adding project..." : "Add project"}
        </button>
      </div>
    </div>
  );
}

export default AddNewProjectForm;
