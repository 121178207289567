import React, { useEffect, useState } from "react";
import {
  getAllDocsByUserSnap,
  createNewDoc,
  setDocData,
} from "../firebase/firebase.config";
import { useSelector } from "react-redux";
import Modal from "../components/Modal";
import AddNewProjectForm from "./forms/AddNewProjectForm";
import { IoMdAdd } from "react-icons/io";
import AddTaskForm from "./forms/AddTaskForm";
import { GoDotFill } from "react-icons/go";

function Projects() {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const currentUser = useSelector((state) => state.userObject);
  const [addProjectMode, setAddProjectMode] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [projects, setProjects] = useState([]);
  const [addTaskMode, setAddTaskMode] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});
  const [tasks, setTasks] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    const fetchTasks = async () => {
      const data = await getAllDocsByUserSnap(
        "tasks",
        currentUser.email,
        (data) => {
          setTasks(data);
          setFilteredTasks(data);
        }
      );
    };
    fetchTasks();
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      const data = await getAllDocsByUserSnap(
        "projects",
        currentUser.email,
        (data) => {
          setProjects(data);
          setFilteredProjects(data);
        }
      );
    };
    fetchProjects();
  }, [currentUser.email]);

  useEffect(() => {
    const fetchCategories = async () => {
      const data = await getAllDocsByUserSnap(
        "categories",
        currentUser.email,
        (data) => {
          // Arrange alphabetically
          data.sort((a, b) => {
            if (a.categoryName < b.categoryName) {
              return -1;
            }
            if (a.categoryName > b.categoryName) {
              return 1;
            }
            return 0;
          });
          setCategories(data);
          setFilteredCategories(data);
          setIsLoading(false);
        }
      );
    };
    fetchCategories();
  }, [currentUser.email]);

  const filters = [
    "all",
    "today",
    "tomorrow",
    "in2days",
    "thisWeek",
    "nextWeek",
    "thisMonth",
    "nextMonth",
    "overdue",
  ];

  useEffect(() => {
    if (activeFilters.length === 0) {
      setFilteredTasks(tasks);
      return;
    }

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const thisWeek = new Date();
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);

    const thisMonth = new Date();
    const nextMonth = new Date();
    nextMonth.setMonth(nextMonth.getMonth() + 1);

    const filteredTasks = tasks.filter((task) => {
      const dueDate = new Date(task.dueDate);

      if (activeFilters.includes("all")) {
        return true;
      }

      if (activeFilters.includes("today")) {
        return dueDate.toDateString() === today.toDateString();
      }
      if (activeFilters.includes("tomorrow")) {
        return dueDate.toDateString() === tomorrow.toDateString();
      }

      if (activeFilters.includes("in2days")) {
        const dayAfterTomorrow = new Date(today);
        dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);

        return dueDate.toDateString() === dayAfterTomorrow.toDateString();
      }

      if (activeFilters.includes("thisWeek")) {
        return dueDate >= thisWeek && dueDate <= nextWeek && dueDate > today;
      }
      if (activeFilters.includes("nextWeek")) {
        return dueDate >= nextWeek && dueDate <= nextMonth;
      }
      if (activeFilters.includes("thisMonth")) {
        return dueDate >= thisMonth && dueDate <= nextMonth;
      }
      if (activeFilters.includes("nextMonth")) {
        return dueDate > nextMonth;
      }
      if (activeFilters.includes("overdue")) {
        return dueDate < today;
      }
    });

    setFilteredTasks(filteredTasks);
  }, [activeFilters]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleAddProject = (e, category) => {
    e.preventDefault();
    setSelectedCategory(category);
    setAddProjectMode(true);
  };

  const handleAddTask = (e, project) => {
    e.preventDefault();
    setSelectedProject(project);
    setAddTaskMode(true);
  };

  const handleFilter = (filter) => {
    setActiveFilters(filter);
  };

  const inactivePillStyle =
    "bg-gray-200 text-gray-600 p-1 rounded-lg text-xs border-blue-200 border-2";
  const activePillStyle = "bg-blue-400 text-white p-1 rounded-lg text-xs";

  const handleTogglePendingComplete = (e, task) => {
    e.preventDefault();
    const updatedTask = {
      ...task,
      taskStatus: task.taskStatus === "pending" ? "completed" : "pending",
      completedAt:
        task.taskStatus === "pending" ? new Date().toISOString() : "",
      completedBy: task.taskStatus === "pending" ? currentUser.email : "",
    };
    setDocData("tasks", updatedTask.id, updatedTask);
  };

  return (
    <div className="p-2 text-sm max-w-[500px]">
      <div>
        <h1 className="text-xl font-bold">Categories</h1>

        <div className="mb-8 flex items-center gap-4 flex-wrap">
          {filters.map((filter) => (
            <button
              key={filter}
              className={
                activeFilters.includes(filter)
                  ? activePillStyle
                  : inactivePillStyle
              }
              onClick={() => handleFilter(filter)}
            >
              {filter}
            </button>
          ))}
        </div>
        <div className="flex flex-wrap gap-8 flex-col ">
          {filteredCategories.map((category) => (
            <div
              key={category.id}
              className="p-2 bg-gray-100 rounded-lg flex flex-col shadow-sm shadow-gray-500"
            >
              <div className="flex items-center gap-4">
                <button
                  className="bg-blue-400 text-white rounded-lg p-1"
                  onClick={(e) => handleAddProject(e, category)}
                >
                  <IoMdAdd />
                </button>
                <div className="font-bold uppercase">
                  {category.categoryName}
                </div>
              </div>

              {/* Projects list under the category */}
              <div className="mt-2">
                {filteredProjects
                  .filter((project) => project.category === category.id)
                  .map((project, index) => (
                    <div key={project.id}>
                      <div className="p-1 bg-gray-200 rounded mt-4 flex items-center justify-between gap-8">
                        <div className="font-bold">
                          <span className="mr-2 text-xs text-gray-500">
                            {index + 1}
                          </span>
                          {project.projectName}
                        </div>
                        <div>
                          <button
                            onClick={(e) => handleAddTask(e, project)}
                            className="text-gray-600 text-xs"
                          >
                            Add Task
                          </button>
                        </div>
                      </div>

                      {/* Tasks list under the project */}
                      <div className="flex flex-col gap-2 mt-4">
                        {filteredTasks
                          .filter((task) => task.projectId === project.id)
                          .map((task) => (
                            <div
                              key={task.id}
                              className="p-1 bg-gray-100 rounded flex items-center justify-between gap-4 ml-8 text-xs"
                            >
                              <div className="font-semibold text-gray-600 flex items-center gap-2">
                                {/* <span className="text-xs text-gray-400">
                                  <GoDotFill />
                                </span> */}

                                <button
                                  className={` text-gray-600 font-normal text-xs p-1 rounded-lg text-xs ${
                                    task.taskStatus === "pending"
                                      ? "bg-red-200"
                                      : "bg-green-500"
                                  }`}
                                  type="button"
                                  onClick={(e) =>
                                    handleTogglePendingComplete(e, task)
                                  }
                                >
                                  {task.taskStatus}
                                </button>
                                {task.taskName}
                              </div>
                              <div className="w-32 text-right">
                                {task.dueDate}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {addProjectMode && (
        <Modal>
          <div className="relative">
            <div
              className="underline top-0 right-2 text-xs absolute cursor-pointer"
              onClick={() => setAddProjectMode(false)}
            >
              Close
            </div>
            <div className="pt-8">
              <AddNewProjectForm category={selectedCategory} />
            </div>
          </div>
        </Modal>
      )}

      {
        //Add task

        addTaskMode && (
          <Modal>
            <div className="relative">
              <div
                className="underline top-0 right-2 text-xs absolute cursor-pointer"
                onClick={() => setAddTaskMode(false)}
              >
                Close
              </div>
              <div className="pt-8">
                <div>
                  <AddTaskForm
                    project={selectedProject}
                    setAddTaskMode={setAddTaskMode}
                  />
                </div>
              </div>
            </div>
          </Modal>
        )
      }
    </div>
  );
}

export default Projects;
