import React from 'react'

function ProgressBar({progress}) {
  return (
    <div>
        <div className="w-full h-4 bg-gray-200 rounded-full">
            <div className="h-full bg-green-500 rounded-full" style={{width: `${progress}%`}}></div>
        </div>
    </div>
  )
}

export default ProgressBar