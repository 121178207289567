import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  signupWithEmail,
  checkUser,
  createUserDoc,
} from "../firebase/firebase.config";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { loadUser } from "../store/userSlice";

function Signup() {
  const inputStyle = "w-full rounded p-1 text-sm py-2 border border-gray-300";
  const labelStyle = "text-left font-bold text-gray-500 text-sm";

  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const checkPasswordFormat = (password) => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return passwordRegex.test(password);
  };

  const checkEmailFormat = (email) => {
    //check if email domain is @infinity8data.com
    const emailRegex = /^[a-zA-Z0-9._-]+@infinity8data.com$/;
    return emailRegex.test(email);
  };

  const checkPasswordMatch = (password, confirmPassword) => {
    return password === confirmPassword;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    const { displayName, email, password, confirmPassword } = formData;
    let err = "";

    if (!checkEmailFormat(email)) {
      err = "Invalid email address";
      setError(err);
      setIsProcessing(false);
      return;
    }
    if (!checkPasswordFormat(password)) {
      err =
        "Password must be at least 8 characters long and contain at least one letter and one number";
      setError(err);
      setIsProcessing(false);
      return;
    }
    if (!checkPasswordMatch(password, confirmPassword)) {
      err = "Password does not match";
      setError(err);
      setIsProcessing(false);
      return;
    }
    setError("");
    //create document object
    const signUp = async () => {
      try {
        const res = await signupWithEmail(email, password);
        if (res) {
          const userCheck = await checkUser(res.user);

          if (!userCheck) {
            await createUserDoc(res.user, { displayName });
            dispatch(loadUser({ email, displayName, uid: res.user.uid }));
            navigate("/");
          } else {
            alert("User already exists, sign in instead");
          }
        }
      } catch (error) {
      }
    };
    signUp();
    setIsProcessing(false);
  };

  return (
    <div
      className="h-screen bg-gray-200 flex flex-col items-center justify-center gap-4"
      style={{
        background:
          "url('https://plus.unsplash.com/premium_photo-1701121214437-95ddcfc131bc?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') no-repeat center center fixed",

        opacity: "0.85",
      }}
    >
      <div className="text-center text-white font-bold bg-orange-400 p-4 rounded w-80">
        Friday.com
      </div>
      <form
        className="flex flex-col w-10/12 items-center gap-2 bg-gray-100 p-4 lg:w-96"
        onSubmit={handleSubmit}
      >
        <div className="font-bold text-orange-500 uppercase">Sign up</div>
        <div className="w-10/12">
          <label className={labelStyle} htmlFor="">
            Full Name
          </label>
          <input
            className={inputStyle}
            type="text"
            placeholder="Enter Full Name"
            name="displayName"
            onChange={handleChange}
            required
          />
        </div>

        <div className="w-10/12">
          <label className={labelStyle} htmlFor="">
            Email Address
          </label>
          <input
            className={inputStyle}
            type="email"
            placeholder="Enter Email Address"
            name="email"
            onChange={handleChange}
            required
          />
        </div>

        <div className="w-10/12">
          <label className={labelStyle} htmlFor="">
            Nominate Password
          </label>
          <input
            className={inputStyle}
            type="password"
            placeholder="Nominate your password"
            name="password"
            onChange={handleChange}
            required
          />
        </div>
        <div className="w-10/12">
          <label className={labelStyle} htmlFor="">
            Confirm Password
          </label>
          <input
            className={inputStyle}
            type="password"
            placeholder="Confirm your password"
            name="confirmPassword"
            onChange={handleChange}
            required
          />
        </div>
        <button
          disabled={isProcessing}
          type="submit"
          className="bg-blue-500 rounded-md text-white px-4 py-2 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isProcessing ? "Processing..." : "Sign up"}
        </button>
        {error && <div className="text-red-500">{error}</div>}
        <div>
          Already Have an Account ? Sign in <Link to="/signin">here</Link>
        </div>
      </form>
    </div>
  );
}

export default Signup;
