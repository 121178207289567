import React from "react";
import { NavLink, Link, Outlet } from "react-router-dom";
import SidebarMenu from "../components/SidebarMenu";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

function Main() {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser.email || currentUser.email === null) {
      navigate("/signin");
    } else {
      navigate("/");
    }
  }, []);

  const links = [
    {
      link: "/",
      label: "Dashboard",
      icon: "dashboard",
    },
    {
      link: "projects",
      label: "Projects",
      icon: "projects",
    },
    {
      link: "tasks",
      label: "All Tasks",
      icon: "tasks",
    },
    {
      link: "profile",
      label: "My Profile",
      icon: "profile",
    },
  ];

  return (
    <>
      <div className="main flex grow">
        <div className="bg-gray-100 min-h-screen w-64 flex-col items-center justify-start hidden lg:block lg:fixed lg:top-0 lg:left-0">
          <h4 className="mt-8 text-center">Friday.app</h4>
          <div
            style={{
              borderBottom: "1px solid #ccc",
            }}
            className="mt-4 text-center font-bold pb-8"
          >
            Welcome {currentUser.displayName}
          </div>
          <SidebarMenu links={links} />
        </div>
        <div className="flex-grow pb-20 lg:pb-0 lg:ml-64">
          <Outlet />
        </div>
      </div>
      <div className="gap-2 z-10 fixed bottom-0 w-full bg-blue-500 text-white h-20 flex items-center justify-around lg:hidden ">
        {links.map((item) => (
          <div className="text-xs" key={item.label}>
            <NavLink to={item.link}>{item.label}</NavLink>
          </div>
        ))}
      </div>
    </>
  );
}

export default Main;
