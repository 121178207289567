import React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { getUserByEmail } from "../firebase/firebase.config";
import { useSelector } from "react-redux";

function UserAvatar({ email }) {
  const [nameInitials, setNameInitials] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [user, setUser] = useState({});
  const [displayName, setDisplayName] = useState("");

  useEffect(() => {
    const getUserPhoto = async () => {
      try {
        const res = await getUserByEmail("users", email);
        setProfilePhoto(res.profilePhoto);
        setDisplayName(res.displayName);
        setUser(res);
      } catch (error) {
      }
    };

    getUserPhoto();
  }, []);

  useEffect(() => {
    const createNameInitials = (name) => {
      if (!name) {
        name = "Unknown";
      }
      const nameSplit = name.split(" ");

      if (nameSplit.length === 1) {
        return nameSplit[0][0];
      } else {
        const firstName = nameSplit[0];
        const lastName = nameSplit[1];

        return firstName[0] + lastName[0];
      }
    };
    setNameInitials(createNameInitials(displayName));
  }, [user]);

  return (
    <div>
      <Stack direction="row" spacing={2}>
        {profilePhoto ? (
          <Avatar
            sx={{ bgcolor: deepOrange[500] }}
            alt={displayName}
            src={profilePhoto}
          />
        ) : (
          <Avatar sx={{ bgcolor: deepOrange[500] }}>{nameInitials}</Avatar>
        )}
      </Stack>
    </div>
  );
}

export default UserAvatar;
