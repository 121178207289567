import React from "react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { subscribeToMyTasks } from "../firebase/firebase.config";
import { useNavigate } from "react-router-dom";

function Tasks() {
  const currentUser = useSelector((state) => state.userObject);
  const [tasks, setTasks] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = subscribeToMyTasks(
      "tasks",
      currentUser.email,
      (updatedTasks) => {
        setTasks(updatedTasks);
      }
    );
    return () => unsubscribe();
  }, [currentUser.email]);

  return (
    <div className="p-4">
      <div className="text-2xl font-bold">Tasks Assigned to You</div>
      {tasks.map((task) => (
        <div
          key={task.id}
          className="bg-gray-100 p-4 mt-4
          cursor-pointer hover:bg-gray-300 border shadow-lg rounded-lg
          lg:max-w-4xl
          "
          onClick={() => {
            navigate(`/project/${task.projectId}`);
          }}
        >
          <div className="text-md font-bold uppercase">{task.taskName}</div>
          <div className="text-sm">Project: {task.projectName}</div>
          <div className="text-sm">Description: {task.taskDescription}</div>
          <div className="text-sm">Due Date: {task.dueDate}</div>
          <div className="text-sm">Assigned by: {task.createdBy}</div>
        </div>
      ))}
    </div>
  );
}

export default Tasks;
