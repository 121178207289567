import React from "react";
import { useEffect, useState } from "react";
import { queryAllDocs } from "../firebase/firebase.config";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function MyProjects() {
  const currentUser = useSelector((state) => state.userObject);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getProjects = async () => {
      try {
        const result = await queryAllDocs("projects", currentUser.email);
        setProjects(result);
        setLoading(false);
      } catch (error) {
      }
    };
    getProjects();
  }, []);

  const handleClick = (e, id) => {
    navigate(`/project/${id}`);
  };

  return (
    <div className="p-4">
      <div>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div>
            <div className="text-2xl font-bold">My Projects</div>
            <div className="flex flex-wrap gap-4 mt-8 items-start">
              {projects.map((project) => {
                return (
                  <div
                    className="bg-orange-200 p-4 rounded-md w-48 cursor-pointer hover:bg-gray-200"
                    onClick={(e) => handleClick(e, project.id)}
                  >
                    <div className="text-xl font-bold">
                      {project.projectName}
                    </div>
                    <div className="text-xs">{project.projectDescription}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MyProjects;
