import React from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";

import {
  getDocDataSnap,
  setDocData,
  createNewDoc,
  queryAllTasks,
  subscribeToTaskUpdates,
  deleteDocById,
} from "../firebase/firebase.config";
import { useState, useEffect } from "react";
import InputBox from "../components/InputBox";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TaskIcon from "@mui/icons-material/Task";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { Accordion } from "@mui/material";
import { AccordionSummary } from "@mui/material";
import { AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UserSearch from "../components/UserSearch";
import TasksMapping from "../components/TasksMapping";

function ProjectView() {
  const { id } = useParams();
  const [projectData, setProjectData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showTaskForm, setShowTaskForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [inProcess, setInProcess] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [originalTasks, setOriginalTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [options, setOptions] = useState([]);

  const [error, setError] = useState("");
  const [editMode, setEditMode] = useState(false);

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const getProjectData = async () => {
      const result = await getDocDataSnap("projects", id, (data) => {
        setProjectData(data);
        setLoading(false);
      });
      return result;
    };
    getProjectData();
  }, [id]);

  useEffect(() => {
    //query all tasks
    const unsubscribe = subscribeToTaskUpdates("tasks", id, (updatedTasks) => {
      setTasks(updatedTasks);
      setOriginalTasks(updatedTasks);
      setFilteredTasks(updatedTasks);
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [id]);

  const { projectName } = projectData;

  useEffect(() => {
    const optionArray = projectData.projectMembers?.map((member) => {
      const name = member.split("@")[0];
      const firstName = name.split(".")[0];
      return { value: member, label: firstName };
    });

    setOptions(optionArray);
  }, [projectData]);

  const handleShowTaskForm = () => {
    setShowTaskForm(!showTaskForm);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formData.id === undefined ||
      formData.id === "" ||
      formData.id === null
    ) {
      const docObject = {
        ...formData,
        projectName: projectName,
        createdAt: new Date(),
        createdBy: currentUser.email,
        createdByUid: currentUser.uid,
        projectId: id,
        taskStatus: "active",
      };

      const newTask = async () => {
        setInProcess(true);
        const result = await createNewDoc("tasks", docObject);
        if (result) {
          toast("Task Created");
        } else {
          setError(result.message);
        }
        setInProcess(false);
      };

      newTask();
    } else {
      const docObject = {
        ...formData,
        taskStatus: "active",
      };
      setDocDataMerge("tasks", formData.id, docObject);
      toast("Task Updated");
    }
    setShowTaskForm(false);
    setFormData({});
  };

  const handleDeleteTask = async (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this task?")) {
      const result = await deleteDocById("tasks", id);
      if (result) {
        toast("Task Deleted");
      } else {
        setError(result.message);
      }
      setInProcess(false);
    } else {
      return;
    }
  };

  const handleClick = (e, user) => {
    e.preventDefault();
    setProjectData({
      ...projectData,
      projectMembers: [...projectData.projectMembers, user.email],
    });
  };

  const handleDelete = (e, member) => {
    e.preventDefault();
    setFormData({
      ...formData,
      projectMembers: formData.projectMembers.filter((item) => item !== member),
    });
  };

  const setDocDataMerge = async (collName, docId, docObject) => {
    try {
      await setDocData(collName, docId, docObject, { merge: true });
    } catch (error) {
    }
  };

  const handleEditMembers = (e) => {
    e.preventDefault();
    setEditMode(!editMode);
    if (editMode) {
      const docObject = {
        ...projectData,
      };
      setDocDataMerge("projects", id, docObject);
      setEditMode(false);
    }
  };

  const handleEdit = (e, task) => {
    e.preventDefault();
    setShowTaskForm(true);
    setEditMode(!editMode);
    setFormData(task);
    setFilteredTasks(tasks.filter((item) => item.id !== task.id));
  };

  const handleComplete = (e, task) => {
    e.preventDefault();
    const docObject = {
      ...task,
      taskStatus: "completed",
      completedAt: new Date(),
    };
    setDocDataMerge("tasks", task.id, docObject);
  };

  const handlePending = (e, task) => {
    e.preventDefault();
    const docObject = {
      ...task,
      taskStatus: "active",
      completedAt: "",
    };
    setDocDataMerge("tasks", task.id, docObject);
  };

  const handleCancel = (e) => {
    setFilteredTasks(originalTasks);
    setEditMode(!editMode);
    setShowTaskForm(false);
  };

  const completedTasks = filteredTasks.filter(
    (item) => item.taskStatus === "completed"
  );

  const activeTasks = filteredTasks.filter(
    (item) => item.taskStatus === "active"
  );

  return (
    <div className="p-4">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="">
          <div className="text-2xl font-bold">{projectName}</div>
          <div className="flex gap-2 mt-4">
            <button
              className="bg-orange-500 p-2 rounded-md text-white mb-4"
              onClick={handleShowTaskForm}
            >
              {!showTaskForm ? "Add Task" : "Hide"}
            </button>
          </div>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="text-orange-600 text-sm font-bold">
                {" "}
                Assign Team Members
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <h4 className=" text-sm mt-4">Select Team Members</h4>
                <div className="text-xs text-gray-700 ">
                  Click Edit Mode to add or remove team members
                </div>
                <UserSearch handleClick={handleClick} disabled={!editMode} />
                <button
                  className="bg-blue-500 text-white px-4 py-1 h-fit rounded-md mt-4 disabled:bg-gray-300 disabled:cursor-not-allowed "
                  onClick={handleEditMembers}
                >
                  {editMode ? "Save" : "Edit Members"}
                </button>
                <h4 className="mt-4 text-sm ">Team Members</h4>
                {projectData.projectMembers && (
                  <div className="flex flex-wrap text-xs">
                    {projectData.projectMembers.map((member) => (
                      <div className=" flex flex-wrap mr-2 mb-1 ">
                        <div className="bg-gray-100 p-2 ">{member}</div>
                        {member !== formData.team_lead && (
                          <button
                            className="bg-red-100 px-3 py-2 disabled:hidden"
                            onClick={(e) => handleDelete(e, member)}
                            disabled={!editMode}
                          >
                            x
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>

          <AnimatePresence mode="popLayout">
            {showTaskForm && (
              <motion.div
                className="mt-4"
                initial={({ opacity: 0 }, { y: -100 })}
                animate={({ opacity: 1 }, { y: 0 })}
              >
                <form
                  className="lg:w-96 flex flex-col lg:flex-row gap-2"
                  onSubmit={handleSubmit}
                >
                  <InputBox
                    label="Task Name"
                    placeholder={"Task Name"}
                    type={"text"}
                    required={true}
                    name={"taskName"}
                    onChange={handleChange}
                    value={formData.taskName}
                  />
                  <InputBox
                    label="Description"
                    placeholder={"Task Description"}
                    type="text"
                    name={"taskDescription"}
                    onChange={handleChange}
                    value={formData.taskDescription}
                    style={{ width: "300px" }}
                  />
                  <InputBox
                    label="Due Date"
                    placeholder={"Due Date"}
                    type="date"
                    required={true}
                    name={"dueDate"}
                    onChange={handleChange}
                    value={formData.dueDate}
                  />
                  <div className="flex flex-col">
                    <label className="text-sm text-gray-500 font-bold">
                      Assigned To
                    </label>
                    <Select
                      className="border border-gray-300 w-60 rounded-md disabled:bg-gray-300 disabled:cursor-not-allowed"
                      options={options}
                      onChange={(e) =>
                        setFormData({ ...formData, assignedTo: e.value })
                      }
                      value={
                        options.filter((option) =>
                          option.value.includes(formData.assignedTo)
                        ) || []
                      }
                      placeholder="Assign To"
                    />
                  </div>

                  <div className="flex items-center gap-2">
                    <button
                      className="bg-blue-500 text-white px-4 py-1 h-fit rounded-md mt-4 disabled:bg-gray-300 disabled:cursor-not-allowed "
                      type="submit"
                      disabled={inProcess}
                    >
                      Save
                    </button>
                    <button
                      className="px-4 py-1 mt-4"
                      type="reset"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </motion.div>
            )}
          </AnimatePresence>
          <h4 className="mt-8">Pending Tasks</h4>
          <TasksMapping
            handleEdit={handleEdit}
            tasks={activeTasks}
            handleDeleteTask={handleDeleteTask}
            handleComplete={handleComplete}
            handlePending={handlePending}
          />
          <h4 className="mt-8">Completed Tasks</h4>
          <TasksMapping
            handleEdit={handleEdit}
            tasks={completedTasks}
            handleDeleteTask={handleDeleteTask}
            handleComplete={handleComplete}
            handlePending={handlePending}
          />
        </div>
      )}
    </div>
  );
}

export default ProjectView;
