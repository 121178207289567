import React from "react";

import TaskIcon from "@mui/icons-material/Task";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import UserAvatar from "./UserAvatar";

function TasksMapping({
  tasks,
  handleDeleteTask,
  handleEdit,
  handleComplete,
  handlePending,
}) {
  return (
    <>
      {tasks.map((task) => (
        <div
          className="bg-gray-100 p-4 rounded-md mt-4 flex gap-2 relative"
          key={task.id}
        >
          <UserAvatar email={task.assignedTo} />
          <div>
            <div className="text-md font-bold">{task.taskName}</div>
            <div className="text-xs">{task.taskDescription}</div>
            <div className="text-xs">Due on: {task.dueDate}</div>
          </div>

          <div className="flex gap-1 float-right absolute bottom-0 right-0">
            {task.taskStatus === "active" && (
              <button
                className="text-green-600"
                onClick={(e) => handleComplete(e, task)}
              >
                <TaskIcon />
              </button>
            )}

            {task.taskStatus === "completed" && (
              <button
                className="text-green-600"
                onClick={(e) => handlePending(e, task)}
              >
                Mark as Pending
              </button>
            )}

            <button
              className="text-orange-600"
              onClick={(e) => handleEdit(e, task)}
            >
              <BorderColorIcon />
            </button>
            <button
              className="text-red-500"
              onClick={(e) => handleDeleteTask(e, task.id)}
            >
              <DeleteIcon />
            </button>
          </div>
        </div>
      ))}
    </>
  );
}

export default TasksMapping;
