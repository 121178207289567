import React, { useEffect } from "react";
import { useRef } from "react";
import BlankPhoto from "../assets/blank.webp";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  storage,
  setDocData,
  getDocDataSnap,
} from "../firebase/firebase.config";
import ProgressBar from "../components/ProgressBar";

function PhotoUpload() {
  const [selectedFile, setSelectedFile] = useState(null);
  const currentUser = useSelector((state) => state.userObject);
  const [profilePhoto, setProfilePhoto] = useState("");
  const [user, setUser] = useState({});
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const getProfilePhoto = async () => {
      try {
        const res = await getDocDataSnap("users", currentUser.uid, (data) => {
          setProfilePhoto(data.profilePhoto);
          setUser(data);
        });
      } catch (error) {
      }
    };
    getProfilePhoto();
  }, []);

  const fileInput = useRef(null);
  const handleUploadPhoto = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const handleUpload = (e) => {
    e.preventDefault();
    setUploading(true);
    const file = e.target.files[0];

    const filename = `users/${currentUser.uid}/profilePhoto/${file.name}`;

    const storageRef = ref(storage, filename);
    const uploadTask = uploadBytesResumable(storageRef, file);

    const unsubscribe = uploadTask.on(
      "state_changed",
      (snap) => {
        let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        setProgress(percentage);
      },
      (err) => {
        setError(err);
      },

      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        const url = downloadURL;
        await setDocData("users", currentUser.uid, { profilePhoto: url });
        setUploading(false);
      }
    );
    return () => {
      uploadTask.cancel();
      unsubscribe();
    };
  };

  return (
    <div>
      <div className="flex gap-2 items-center">
        <div className="h-32 w-32 flex items-center justify-center p-0 ">
          <img
            src={user ? user.profilePhoto : BlankPhoto}
            className="h-32 w-32 rounded-full flex items-center justify-center object-cover text-xs"
            onClick={handleUploadPhoto}
          />

          <input
            type="file"
            className="hidden"
            ref={fileInput}
            accept="image/*"
            onChange={handleUpload}
          />
        </div>
        <div className="text-xl font-bold mt-4">{user.displayName}</div>
        <div className="w-60 h-2">
          {uploading && <ProgressBar progress={progress} />}
        </div>
      </div>
    </div>
  );
}

export default PhotoUpload;
