import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import { getStorage } from "firebase/storage";

import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  getDocs,
  writeBatch,
  deleteDoc,
  query,
  where,
  addDoc,
  orderBy,
  onSnapshot,
} from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCLKFzmq2e9N-UhccxGIWBzruvzuId8BYM",
  authDomain: "fridayapp-9206b.firebaseapp.com",
  projectId: "fridayapp-9206b",
  storageBucket: "fridayapp-9206b.appspot.com",
  messagingSenderId: "474517921594",
  appId: "1:474517921594:web:d9efebbaa65d687ed9ba03",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();

export default app;

export const storage = getStorage(app);

export const db = getFirestore();

export const signupWithEmail = async (email, password) => {
  try {
    const response = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    return response;
  } catch (error) {
    if (error.code === "auth/email-already-in-use") {
      alert("Email already in use, please sign in instead");
    }
  }
};

export const signInWithEmail = async (email, password) => {
  try {
    const response = await signInWithEmailAndPassword(auth, email, password);
    return response;
  } catch (error) {
    if (error.code === "auth/user-not-found") {
      alert("User not found, please sign up instead");
    }
  }
};

export const checkUser = async (userAuth) => {
  if (userAuth) {
    //check if user is existing
    const userRef = doc(db, "users", userAuth.uid);
    const userSnapShot = await getDoc(userRef);

    if (!userSnapShot.exists()) {
      return false;
    } else {
      return true;
    }
  }
};

export const createUserDoc = async (userAuth, additionalData) => {
  if (!userAuth) return;

  const colref = collection(db, "users");
  const userRef = doc(colref, userAuth.uid);
  const snapShot = await getDoc(userRef);

  if (!snapShot.exists()) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();
    try {
      await setDoc(userRef, {
        displayName,
        email,
        createdAt,
        ...additionalData,
      });
    } catch (error) {
      alert(error.message);
    }
  }
  return userRef;
};

export const findUserDocbyId = async (id) => {
  const userRef = doc(db, "users", id);
  const snapShot = await getDoc(userRef);
  if (snapShot.exists()) {
    return snapShot.data();
  } else {
    return false;
  }
};

export const createNewDoc = async (collectionName, data) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = await addDoc(collectionRef, data);
    return docRef;
  } catch (error) {
    alert(error.message);
    return error;
  }
};

export const getDocData = async (collectionName, id) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = doc(collectionRef, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return false;
    }
  } catch (error) {
    return error;
  }
};

export const getDocDataSnap = async (collectionName, id, callback) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = doc(collectionRef, id);

    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        callback(docSnap.data());
      } else {
        callback(false);
      }
    });

    // Return the unsubscribe function so that you can stop listening when needed
    return unsubscribe;
  } catch (error) {
    console.error("Error fetching document:", error);
    throw error; // Re-throw the error for handling in the calling code
  }
};

export const setDocData = async (collectionName, id, data) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = doc(collectionRef, id);
    await setDoc(docRef, data, { merge: true });
    return true;
  } catch (error) {
    return error;
  }
};

export const getUserByEmail = async (collectionName, email) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    const docs = [];

    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs[0];
  } catch (error) {
    return error;
  }
};

export const getAllDocs = async (collectionName) => {
  try {
    const collectionRef = collection(db, collectionName);
    const querySnapshot = await getDocs(collectionRef);
    const docs = [];
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs;
  } catch (error) {
    return error;
  }
};

export const queryAllDocs = async (collectionName, email) => {
  try {
    const q = query(
      collection(db, collectionName),
      where("projectMembers", "array-contains", email)
    );
    const querySnapshot = await getDocs(q);
    const docs = [];
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs;
  } catch (error) {
    return error;
  }
};

export const queryAllTasks = async (collectionName, id) => {
  try {
    const q = query(
      collection(db, collectionName),
      where("projectId", "==", id)
    );
    const querySnapshot = await getDocs(q);
    const docs = [];
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs;
  } catch (error) {
    return error;
  }
};

export const subscribeToTaskUpdates = (collectionName, id, callback) => {
  try {
    const q = query(
      collection(db, collectionName),
      where("projectId", "==", id)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });

      // Invoke the callback with the updated data
      callback(docs);
    });

    // Return the unsubscribe function to stop listening to updates when needed
    return unsubscribe;
  } catch (error) {
    console.error("Error subscribing to updates:", error);
    throw error;
  }
};

export const subscribeToMyTasks = (collectionName, email, callback) => {
  try {
    const q = query(
      collection(db, collectionName),
      where("assignedTo", "==", email),
      where("taskStatus", "==", "active")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });

      // Invoke the callback with the updated data
      callback(docs);
    });

    // Return the unsubscribe function to stop listening to updates when needed
    return unsubscribe;
  } catch (error) {
    console.error("Error subscribing to updates:", error);
    throw error;
  }
};

export const getAllDocsSnap = (collectionName, callback) => {
  try {
    const collectionRef = collection(db, collectionName);

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      // Invoke the callback with the updated data
      callback(docs);
    });

    // Return the unsubscribe function to stop listening to updates when needed
    return unsubscribe;
  } catch (error) {
    console.error("Error fetching documents:", error);
  }
};

export const getAllProjectsByUser = (collectionName, email, callback) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(
      collectionRef,
      where("projectMembers", "array-contains", email)
    );

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      // Invoke the callback with the updated data
      callback(docs);
    });

    // Return the unsubscribe function to stop listening to updates when needed
    return unsubscribe;
  } catch (error) {
    console.error("Error fetching documents:", error);
  }
};

export const deleteDocById = async (collectionName, id) => {
  try {
    const collectionRef = collection(db, collectionName);
    const docRef = doc(collectionRef, id);
    await deleteDoc(docRef);
    return true;
  } catch (error) {
    return error;
  }
};

export const resetpassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (error) {
    return error;
  }
};

export const getAllDocsByUser = async (collectionName, email) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where("user", "==", email));
    const querySnapshot = await getDocs(q);
    const docs = [];
    querySnapshot.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs;
  } catch (error) {
    return error;
  }
};

export const getAllDocsByUserSnap = (collectionName, email, callback) => {
  try {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef, where("user", "==", email));

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      // Invoke the callback with the updated data
      callback(docs);
    });

    // Return the unsubscribe function to stop listening to updates when needed
    return unsubscribe;
  } catch (error) {
    console.error("Error fetching documents:", error);
  }
};
