import React from "react";
import { useState, useEffect } from "react";
import { getAllDocs } from "../firebase/firebase.config";

function UserSearch({ handleClick, disabled}) {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const res = await getAllDocs("users");
        setUsers(res);
        setFilteredUsers(res);
        setLoading(false);
      } catch (error) {
      }
    };
    getUsers();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const filteredUsers = users.filter((user) => {
      return user.email.toLowerCase().includes(search.toLowerCase());
    });
    setFilteredUsers(filteredUsers);
  };

  const handleUserClick = (e, user) => {
    handleClick(e, user);
    setSearch(""); // Reset the search input after clicking a user
  };

  return (
    <div className="relative">
      <input
        type="text"
        placeholder="Search user here"
        value={search}
        onChange={handleSearch}
        disabled={disabled}
        className="disabled:text-gray-800 disabled:bg-gray-200 w-80 px-4 py-2 mt-2 border rounded-md border-gray-400 focus:outline-none focus:border-blue-500"
      />
      {search.length > 0 && (
        <div className="absolute left-0 right-0 mt-10 bg-white border rounded-md border-gray-400">
          {filteredUsers.slice(0, 5).map((user) => (
            <div
              className="hover:bg-gray-200 text-sm bg-gray-100 mt-1 p-2 flex flex-wrap h-12"
              key={user.email}
            >
              <p onClick={(e) => handleUserClick(e, user)}>{user.displayName}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default UserSearch;