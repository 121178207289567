
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Signup from "./pages/Signup";
import Dashboard from "./pages/Dashboard";
import Signin from "./pages/Signin";
import Main from "./pages/Main";
import NewProject from "./pages/Projects";
import MyProjects from "./pages/MyProjects";
import Tasks from "./pages/Tasks";
import ProjectView from "./pages/ProjectView";
import Projects from "./pages/Projects";
import Profile from "./pages/Profile";
import ResetPassword from "./pages/ResetPassword";



function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/" element={<Main />} >
            <Route index element={<Projects />} />
            <Route path='projects' element={<Projects />} />
            <Route path='tasks' element={<Tasks />} />
            <Route path='myprojects' element={<MyProjects />} />
            <Route path='project/:id' element={<ProjectView />} />
            <Route path='profile' element={<Profile />} />
          </Route>
          <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
      </Router>
    </div>
  );
}



export default App;
