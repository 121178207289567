import React from "react";

import { useSelector } from "react-redux";
import InputBox from "../components/InputBox";
import { useState, useEffect } from "react";
import { setDocData, getDocDataSnap } from "../firebase/firebase.config";
import { toast } from "react-toastify";
import PhotoUpload from "../components/PhotoUpload";

function Profile() {
  const currentUser = useSelector((state) => state.userObject);
  const [formData, setFormData] = useState({});
  const [editMode, setEditMode] = useState(false);

  const fileInput = React.useRef(null);

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await getDocDataSnap("users", currentUser.uid, (data) => {
          setFormData(data);
        });
      } catch (error) {
      }
    };
    getUser();
  }, []);

  const handleEditMode = (e) => {
    e.preventDefault();
    setEditMode(!editMode);

    if (editMode) {
      // Save the data
      setDocData("users", currentUser.uid, formData);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="p-4">
      <PhotoUpload />
     

      <div className="max-w-2xl mt-8">
        <div>Profile Information</div>
        <div className="mt-4">
          <form>
            <InputBox
              label="Name"
              placeholder="Name"
              type="text"
              name="displayName"
              value={formData.displayName || ""}
              disabled={!editMode}
              onChange={handleChange}
            />
            <div className="flex flex-col mt-4">
              <label className="text-sm text-gray-500 font-bold">Bio</label>
              <textarea
                className="border border-gray-300 rounded-md px-2 py-1"
                name="bio"
                id=""
                rows="10"
                placeholder="Make a short description about yourself"
                disabled={!editMode}
                onChange={handleChange}
                value={formData.bio || ""}
              ></textarea>
            </div>
            <button
              onClick={handleEditMode}
              className="bg-blue-500 text-white px-4 py-1 h-fit rounded-md mt-4"
            >
              {editMode ? "Save" : "Edit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Profile;
